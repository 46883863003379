import React from "react";
import { Container, Row, Col, Card } from "reactstrap";
import Fade from "react-reveal/Fade";
import "./home.css";
import Test from '../Test';
import $ from "jquery";


const categories = [ 
  {
    name: 'Claims of Racism',
    icon: 'fas fa-fist-raised',
    hyperLink: '/racism-claims'
  },
  {
    name: 'History Rewriting',
    icon: 'fas fa-history',
    hyperLink: '/history-rewriting'
  },
  {
    name: 'Claims of Feminism',
    icon: 'fas fa-venus',
    hyperLink: '/'
  },
  {
    name: 'Environmental Claims',
    icon: 'fas fa-leaf',
    hyperLink: '/'
  },
  {
    name: 'Economic Claims',
    icon: 'fas fa-building',
    hyperLink: '/'
  },
  {
    name: 'Smear & Cancel',
    icon: 'fas fa-users-slash',
    hyperLink: '/'
  },
  {
    name: 'Human Nature',
    icon: 'fas fa-user-md',
    hyperLink: '/claims-on-human-nature'
  },
  {
    name: 'Blame Christianity',
    icon: 'fas fa-bible',
    hyperLink: '/'
  },
]
const Home = () => {

  const renderCards = categories.map((category, i)=> {
    return <Col key={i} sm="3">
    <a href={category.hyperLink} style={{ color: "inherit", textDecoration: "none" }}>
    
    <Card body className="highlight-on-hover">
    <div className="circledItem-home">
      <i className={category.icon}></i>
    </div>
    <h6 style={{ textAlign: "center" }}>
      <strong>{category.name}</strong>
    </h6>
  </Card>
  </a>
  </Col>
  })

  return (
    <React.Fragment>
      <Container id="home">
        <br />
        <Fade>
            <Row>
                {renderCards}    
            </Row>
        </Fade>
      </Container>
    </React.Fragment>
  );
};

export default Home;
