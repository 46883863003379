import React from 'react';
import './CssGlossary.css';
import { Container } from 'reactstrap';
import Fade from 'react-reveal/Fade';





const Glossary = () => {
    return (
  
        <React.Fragment>
            <Container>
                   Glossary 
            </Container>
       

        </React.Fragment>
    );
}

export default Glossary