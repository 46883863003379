import React, { useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';

import './App.css';
import Home from './components/Home';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import About from './components/About';
import Glossary from './components/Glossary';
import Header from './components/Header';
import VerticalNavBar from './components/VerticalNavBar';
import $ from 'jquery';
import BreonnaTaylor from './components/Examples/RacismClaimsExamples/BreonnaTaylor';
import RacismClaims from './components/RacismClaims';
import HistoryRewriting from './components/HistoryRewriting/HistoryRewriting';
import HumanNature from './components/HumanNature';



const testing = [ {name: 'batman', power: 'intelligence and money'}, {name: 'spiderman', power: 'spider powers'}, {name: 'ironman', power: 'intelligence and money'}]
const App = () => {

  return (
    <React.Fragment>
    <Header/>
    <div style={{background: 'linear-gradient(azure, white)'}}>

    <VerticalNavBar />


      <Router>


        {/* //////////////////////////////////////////////////////////////////////// */}
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/about" component={About} />
          <Route path="/glossary" component={Glossary} />
          <Route path="/breonna-taylor" component={BreonnaTaylor} />
          <Route path="/racism-claims" component={RacismClaims} />
          <Route path="/history-rewriting" component={HistoryRewriting} />
          <Route path="/claims-on-human-nature" component={HumanNature} />
          <Route render={() => <h2 style={{ paddingTop: '15%' }}>404 Page not found</h2>} />

        </Switch>
        {/* //////////////////////////////////////////////////////////////////////// */}


{/*   <Footer /> */}
      </Router>

      </div>
    </React.Fragment>
  );
}

export default App;
