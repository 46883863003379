import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  CardHeader,
  CardFooter,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap";
import "./racismClaims.css";
import Fade from "react-reveal/Fade";
import $ from "jquery";

const claims = [
  {
    item: "The Weaponization of Breonna Taylor's death",
    type: "Deception",
    hyperLink: "/breonna-taylor",
  },
  {
    item: "The Weaponization of George Floyd death",
    type: "Lies",
    hyperLink: "/george-floyd",
  },
  {
    item: "The Weaponization of Rayshard Brooks death",
    type: "Deception",
    hyperLink: "/rayshard-brooks",
  },
  {
    item: "The Trayvon Martin Weapon",
    type: "fas fa-leaf",
    hyperLink: "/trayvon-margin",
  },
  {
    item: "The Eric Gardner Weapon",
    type: "fas fa-building",
    hyperLink: "/eric-gardner",
  },
  {
    item: "School drop-out rates",
    type: "fas fa-users-slash",
    hyperLink: "/black-drop-out-rates",
  },
  {
    item: "Poverty",
    type: "fas fa-user-md",
    hyperLink: "/poverty",
  },
  {
    item: "Policing",
    type: "fas fa-bible",
    hyperLink: "/policing",
  },
];
const RacismClaims = () => {
  const renderCards = claims.map((claim, i) => {
    return (
      <Col key={i} sm="3" style={{ marginBottom: "3%" }}>
        <a
          href="/breonna-taylor"
          style={{ color: "inherit", textDecoration: "none" }}
        >
          <Card className="highlight-on-hover claims">
            <CardHeader style={{background: 'linear-gradient(aliceblue, white)'}}>
              <strong>{claim.item}</strong>
            </CardHeader>
            {/*

            <CardBody>
              <CardTitle>Weapon Type: {claim.type}</CardTitle>
              <CardText>
                The claim: With supporting text below as a natural lead-in to
                additional content.
              </CardText>
            </CardBody>
             
          */}
          </Card>
        </a>
      </Col>
    );
  });

  return (
    <React.Fragment>
      <Container id="racismClaims">
        <br />
        <h2 style={{display: 'flex', marginBottom: '2%'}}>
        The "Racism" Claim
          <div className="circledItem">
              <i className="fas fa-fist-raised"></i>
          </div>
        </h2>
          The claim that America was founded on (and that it continues to be rifed with) racism is one of the most devastating weapons of the CCW. The deployment strategy of this 
          weapon is threefold: First, it uses <a href="/history-rewriting" className="embedded-link">history revisionism</a> to demoralize Americans
          into believing <a href="american-values-foundations" className="embedded-link">American Values</a> are "inherently racist" and therefore, not worth perserving. 
          <br/><br/>
          The second strategy is to force a <a href="/history-rewriting" className="embedded-link">semantic shift</a> around the word "racism". Whereas racism
          is <i>"the belief that people from different races have different qualities/abilities that makes them 'inferior' or 'superior' to one another"</i>, its
          original definition has been artificially extended to also include cultural critiques and stereotypes, whether these warranted or not, as acts of racism. They are not. 
          <br/>
          <br/>


          <a href="/racialization" className="embedded-link">Racialization</a> is this weapon's thrid deployment strategy. By positing that all our social 
          interactions revolve around "race and power" (the ideological vestigues of Marx's "proletariat vs bourgeoisie") they are able to force narrative of "victimhood".      

        
          <br />
          <br />
          <Fade>
          <Row>
            {renderCards}
          </Row>
          </Fade>
      </Container>
    </React.Fragment>
  );
};

export default RacismClaims;



          {/* By 
          claiming that that underlie American values are "white supremacy" in need to be discarted and 
          (in their own very crafty words) in need to be "re-imagined". */} 
          