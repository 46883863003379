import React from 'react';

const Header = () => {
return(
    <React.Fragment>
            <div className="nav-bottom-shadow">
                <div className="" style={{display: 'flex', justifyContent: 'flex-end', height: '80px'}} >
                    <div style={{boxShadow: '5px 5px red'}}>
                     <h4 style={{ float: 'right', marginTop: '7%',}}><strong>&nbsp; &nbsp;Weapons</strong> <small>of the</small> <strong>Civil Cold War &nbsp; &nbsp;</strong></h4>
                    </div> 
                </div>  
            </div>  
      </React.Fragment>
)}

export default Header