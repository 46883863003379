import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  CardHeader,
  CardFooter,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap";
import Fade from "react-reveal/Fade";
import $ from "jquery";

const claims = [
  {
    item: "Example",
    type: "Deception",
    hyperLink: "/hello",
  },
  {
    item: "Example 1",
    type: "Lies",
    hyperLink: "/",
  },
  {
    item: "Example 2",
    type: "Deception",
    hyperLink: "/",
  },
  {
    item: "Example 3",
    type: "fas fa-leaf",
    hyperLink: "/",
  },
  {
    item: "Example 4",
    type: "fas fa-building",
    hyperLink: "/",
  },
  {
    item: "Example 5",
    type: "fas fa-users-slash",
    hyperLink: "/",
  },
  {
    item: "Example 6",
    type: "fas fa-user-md",
    hyperLink: "/",
  },
  {
    item: "Example 7",
    type: "fas fa-bible",
    hyperLink: "/",
  },
];
const HumanNature = () => {
  const renderCards = claims.map((claim, i) => {
    return (
      <Col key={i} sm="3" style={{ marginBottom: "3%" }}>
        <a
          href={claim.hyperLink}
          style={{ color: "inherit", textDecoration: "none" }}
        >
          <Card className="highlight-on-hover claims">
            <CardHeader style={{background: 'linear-gradient(aliceblue, white)'}}>
              <strong>{claim.item}</strong>
            </CardHeader>
            {/*

            <CardBody>
              <CardTitle>Weapon Type: {claim.type}</CardTitle>
              <CardText>
                The claim: With supporting text below as a natural lead-in to
                additional content.
              </CardText>
            </CardBody>
             
          */}
          </Card>
        </a>
      </Col>
    );
  });

  return (
    <React.Fragment>
      <Container id="racismClaims">
        <br />
        <h2 style={{display: 'flex'}}>
        Claims on Human Nature
          <div className="circledItem">
              <i className="fas fa-user-md"></i>
          </div>
        </h2>
        <br />

        <Fade>
          Lorem ipsum dolor sit amet, this is a weapon because consectetur
          adipiscing elit. Sed ut orci dolor. Aenean nec consequat turpis, ut
          porttitor dolor. Sed feugiat quam magna, vel tempor quam pharetra ut.
          Sed magna eros, sagittis id quam quis, aliquam ultrices arcu. Mauris
          vel egestas elit, eget interdum ligula. Sed sed quam gravida, euismod
          sem ut, euismod risus. Maecenas egestas venenatis vestibulum. Nunc
          tincidunt sodales metus quis tempor. Mauris eleifend dapibus
          pellentesque.
          <br />
          <br />
          <Row>
            {renderCards}
          </Row>
        </Fade>
      </Container>
    </React.Fragment>
  );
};

export default HumanNature;
