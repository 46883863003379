import React from 'react';
import { Container } from 'reactstrap';
import Fade from 'react-reveal/Fade';





const BreonnaTaylor = () => {
    return (
  
        <React.Fragment>
        <Fade>
            <Container>
            <br/>

            <section style={{display: 'flex'}}>

            <div style={{flex: '1', marginRight: '5%', marginBottom: '5%'}}>
            <h2>The Claim</h2>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut orci dolor. Aenean nec consequat turpis, ut porttitor dolor. 
            Sed feugiat quam magna, vel tempor quam pharetra ut. Sed magna eros, sagittis id quam quis, aliquam ultrices arcu. Mauris vel egestas elit, eget interdum ligula. Sed sed quam gravida, euismod sem ut, euismod risus. Maecenas egestas venenatis vestibulum. Nunc tincidunt sodales metus quis tempor. Mauris eleifend dapibus pellentesque. Nulla finibus mollis ultrices. Etiam vitae molestie metus. Nullam quis ornare nunc. 
            Vivamus vitae diam eget eros convallis sodales quis vitae velit. Fusce lectus dui, aliquam ac lorem eu, porta aliquam elit. 
            Donec congue vitae nisi consectetur posuere. Aenean ultricies sem at aliquam aliquet. Phasellus quis metus nisi. Quisque elementum id nisi nec tincidunt. Nunc vitae lorem mi. Pellentesque vehicula ante faucibus tortor lobortis tristique. Fusce facilisis nulla sit amet varius ornare. Proin egestas diam at lectus egestas, a blandit ligula finibus.
            </div>

            <div  style={{borderLeft: '1px solid grey'}} >
            </div>
            <div style={{flex: '1', marginLeft: '5%', marginBottom: '5%'}}>
            <h2>The Fact</h2>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut orci dolor. Aenean nec consequat turpis, ut porttitor dolor. 
            Sed feugiat quam magna, vel tempor quam pharetra ut. Sed magna eros, sagittis id quam quis, aliquam ultrices arcu. Mauris vel egestas elit, eget interdum ligula. Sed sed quam gravida, euismod sem ut, euismod risus. Maecenas egestas venenatis vestibulum. Nunc tincidunt sodales metus quis tempor. Mauris eleifend dapibus pellentesque. Nulla finibus mollis ultrices. Etiam vitae molestie metus. Nullam quis ornare nunc. 
            Vivamus vitae diam eget eros convallis sodales quis vitae velit. Fusce lectus dui, aliquam ac lorem eu, porta aliquam elit. 
            Donec congue vitae nisi consectetur posuere. Aenean ultricies sem at aliquam aliquet. Phasellus quis metus nisi. Quisque elementum id nisi nec tincidunt. Nunc vitae lorem mi. Pellentesque vehicula ante faucibus tortor lobortis tristique. Fusce facilisis nulla sit amet varius ornare. Proin egestas diam at lectus egestas, a blandit ligula finibus.
            </div>
            </section>


            </Container>
        </Fade>
        </React.Fragment>
    );
}

export default BreonnaTaylor